exports.linkResolver = (doc) => {

    const lng = doc.lang.substring(0,2)
    
    // URL for a page type
    if (doc.type === 'page') {
        if (doc.uid === 'index') {
            return `/${lng}`
        }
        let parent = doc.data ? (doc.data.parent ? doc.data.parent.uid : false ) : false;
        return '/' + [lng, parent, doc.uid].filter(Boolean).join('/')
    }

    // URL for a page type
    if (doc.type === 'news') {
        if (doc.document) {
            return doc.document.url;
        }
        let parent = doc.data ? (doc.data.category ? doc.data.category.uid : false ) : false;
        return '/' + [lng, parent, doc.uid].filter(Boolean).join('/')
    }

    // URL for a page type
    if (doc.type === 'event') {
        if (doc.document) {
            return doc.document.url;
        }
        let parent = doc.data ? (doc.data.category ? doc.data.category.uid : false ) : false;
        return '/' + [lng, parent, doc.uid].filter(Boolean).join('/')
    }
    

    // Backup for all other types
    return `/${lng}/${doc.uid}`
}